<template>
  <v-dialog
    v-model="dialog"
    persistent
    :fullscreen="$vuetify.breakpoint.mobile"
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Add New Collection Address</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <div class="col-12 pt-4">
          <div class="row">
            <div class="col-12 col-sm-6">
              <v-text-field
                class
                v-model="formData.title"
                label="Title"
                clearable
                outlined
                dense
                :error-messages="titleErrors"
                @input="$v.formData.title.$touch()"
                @blur="$v.formData.title.$touch()"
              ></v-text-field>
            </div>
            <div class="col-12 col-sm-6">
              <v-text-field
                class
                v-model="formData.name"
                label="Name"
                clearable
                outlined
                dense
                :error-messages="nameErrors"
                @input="$v.formData.name.$touch()"
                @blur="$v.formData.name.$touch()"
              ></v-text-field>
            </div>
            <div class="col-12 col-sm-6">
              <v-text-field
                class
                v-model="formData.surname"
                label="Surname"
                clearable
                outlined
                dense
              ></v-text-field>
            </div>
            <div class="col-12 col-sm-6">
              <VueTelInputVuetify
                class="ma-0 pa-0 mx-1"
                outlined
                v-model="formData.phone"
                :inputOptions="{ showDialCode: false, tabindex: 0 }"
                mode="international"
                dense
                disabledFetchingCountry
                defaultCountry="AE"
                :error-messages="phoneErrors"
                @input="$v.formData.phone.$touch()"
                @blur="$v.formData.phone.$touch()"
              ></VueTelInputVuetify>
            </div>
            <div class="col-12 col-sm-6">
              <v-autocomplete
                @change="onCountryChange"
                v-model="$v.formData.country.$model"
                label="Country"
                item-text="text"
                item-value="index"
                :items="serverData.countries"
                clearable
                outlined
                dense
                :error-messages="countryErrors"
                @input="$v.formData.country.$touch()"
                @blur="$v.formData.country.$touch()"
              ></v-autocomplete>
            </div>
            <div class="col-12 col-sm-6">
              <v-combobox
                v-model="$v.formData.state.$model"
                :search-input.sync="state_text"
                :items="serverData2.states"
                :disabled="isStatesLoaded"
                :messages="oldValueText('state')"
                item-text="title"
                item-value="title"
                label="State"
                outlined
                required
                dense
                :error-messages="stateErrors"
                @input="$v.formData.state.$touch()"
                @blur="$v.formData.state.$touch()"
                @change="onStateChange"
                @keyup="updateValue('state')"
              >
                <template v-slot:append-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <button
                          class="btn btn-info pl-2"
                          @click="
                            createNewItem(
                              'state',
                              formData.country_id,
                              onCountryChange,
                              state_text
                            )
                          "
                        >
                          Create new
                        </button>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </div>
            <div class="col-12 col-sm-6">
              <v-combobox
                v-model="$v.formData.city.$model"
                :search-input.sync="city_text"
                :items="serverData2.cities"
                :disabled="isCitiesLoaded"
                :messages="oldValueText('city')"
                item-text="title"
                item-value="title"
                label="City"
                persistent-hint
                outlined
                dense
                :error-messages="cityErrors"
                @input="$v.formData.city.$touch()"
                @blur="$v.formData.city.$touch()"
                @change="onCityChange"
                @keyup="updateValue('city')"
              >
                <template v-slot:append-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <button
                          class="btn btn-info pl-2"
                          @click="
                            createNewItem(
                              'city',
                              formData.state.id,
                              onStateChange,
                              city_text
                            )
                          "
                        >
                          Create new
                        </button>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </div>
            <div class="col-12 col-sm-6">
              <v-combobox
                :disabled="isAreasLoaded"
                v-model="formData.area"
                :search-input.sync="area_text"
                :items="serverData2.areas"
                :rules="[validationValueCheck('area')]"
                :messages="oldValueText('area')"
                item-text="title"
                item-value="title"
                label="Area"
                persistent-hint
                outlined
                dense
                @keyup="updateValue('area')"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <button
                          class="btn btn-info pl-2"
                          @click="
                            createNewItem(
                              'area',
                              formData.city.id,
                              onCityChange,
                              area_text
                            )
                          "
                        >
                          Create new
                        </button>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </div>

            <div class="col-12 col-sm-6">
              <v-text-field
                v-model="$v.formData.address.$model"
                label="Address 1"
                clearable
                outlined
                dense
                :error-messages="addressErrors"
                @input="$v.formData.address.$touch()"
                @blur="$v.formData.address.$touch()"
              ></v-text-field>
            </div>
            <div class="col-12 col-sm-6">
              <v-text-field
                v-model="formData.address2"
                label="Address 2"
                clearable
                outlined
                dense
              ></v-text-field>
            </div>
            <div class="col-12 col-sm-6">
              <v-text-field
                v-model="formData.postal_code"
                label="Postal code"
                clearable
                outlined
                dense
              ></v-text-field>
            </div>
          </div>
          <CreateLocation
            :location-id="getLocationId"
            :location-type="getLocationType"
            :location-function="getLocationFunc"
            :locationSetValue="setTextValue"
            :initValue="getLocationInitValue"
            ref="location"
          ></CreateLocation>
        </div>
        <v-main class="text-center mt-2 col-12 elevation-0">
          <button class="btn btn-light mx-3 px-5 py-3 ls1" @click="resetData">
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitData"
          >
            Submit
          </button>
          <!--          <v-btn class="mx-1" color="primary" @click="submitData">-->
          <!--            Submit-->
          <!--          </v-btn>-->
          <!--          <v-btn class="mx-1" color="red" dark @click="resetData">-->
          <!--            Clear-->
          <!--          </v-btn>-->
        </v-main>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import CreateLocation from "./CreateLocation.vue";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
import Swal from "sweetalert2";
export default {
  name: "CollectionAddress",
  mixins: [validationMixin],
  components: { VueTelInputVuetify, CreateLocation },
  props: [
    "serverData",
    "pageLoader",
    "setData",
    "initData",
    "customer_id",
    "getStep2Data",
  ],
  validations() {
    return {
      formData: {
        title: { required },
        name: { required },
        phone: { required },
        country: { required },
        state: { required },
        city: { required },
        address: { required },
      },
    };
  },
  data: () => ({
    dialog: false,
    state_text: null,
    city_text: null,
    area_text: null,

    location_type: null,
    locaiton_id: null,
    location_function: null,
    location_initValue: null,
    serverData2: {
      states: null,
      cities: null,
      areas: null,
    },

    old_address: {
      state: null,
      city: null,
      area: null,
    },

    formData: {
      title: null,
      name: null,
      surname: null,
      phone: null,
      country: null,
      state: null,
      city: null,
      area: null,
      address: null,
      address2: null,
      postal_code: null,
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.resetData();
      }
      this.dialog = !this.dialog;
    },
    updateValue(type) {
      let timer;
      const waitTime = 1250;
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.onBlurValueCheck(type);
      }, waitTime);
    },
    oldValueText(type) {
      let message = "";
      switch (type) {
        case "state":
          if (this.old_address.state) {
            message = `Received: ${this.old_address.state}`;
          }

          break;
        case "city":
          if (this.old_address.city) {
            message = `Received: ${this.old_address.city}`;
          }
          break;
        case "area":
          if (this.old_address.area) {
            message = `Received: ${this.old_address.area}`;
          }
          break;
      }
      return message;
    },
    onBlurValueCheck(type) {
      switch (type) {
        case "state":
          if (
            this.serverData2.states.filter(
              (state) => state.title == this.state_text
            ).length === 0
          ) {
            setTimeout(() => {
              // this.state_text = "";
              this.serverData2.cities = null;
              this.serverData2.areas = null;
            }, 1000);
          } else {
            this.onStateChange(
              this.serverData2.states.filter(
                (state) => state.title == this.state_text
              )[0]
            );
          }

          break;
        case "city":
          if (
            this.serverData2.cities.filter(
              (city) => city.title == this.city_text
            ).length === 0
          ) {
            setTimeout(() => {
              // this.city_text = "";
              this.serverData2.areas = null;
            }, 1000);
          } else {
            this.onCityChange(
              this.serverData2.cities.filter(
                (city) => city.title == this.city_text
              )[0]
            );
          }
          break;
        case "area":
          if (
            this.serverData2.areas.filter(
              (area) => area.title == this.area_text
            ).length === 0
          ) {
            setTimeout(() => {
              // this.area_text = "";
            }, 2000);
          } else {
            this.shipping_address.area = this.serverData2.areas.filter(
              (area) => area.title == this.area_text
            )[0].title;
          }
          break;
      }
    },
    validationValueCheck(type) {
      let validation = false;
      switch (type) {
        case "state":
          try {
            if (
              this.serverData2.states.filter(
                (state) => state.title == this.state_text
              ).length === 0
            ) {
              validation = "Please, select valid state!";
              this.serverData2.cities = null;
              this.serverData2.areas = null;
            }
          } catch {
            break;
          }

          break;
        case "city":
          try {
            if (
              this.serverData2.cities.filter(
                (city) => city.title == this.city_text
              ).length === 0
            ) {
              validation = "Please, select valid city!";
              this.serverData2.areas = null;
            }
          } catch {
            break;
          }

          break;
        case "area":
          try {
            if (
              this.serverData2.areas.filter(
                (area) => area.title == this.area_text
              ).length === 0
            ) {
              validation = "Please, select valid area!";
            } else {
              validation = true;
            }
          } catch {
            break;
          }

          break;
      }
      return validation;
    },
    setTextValue(type, value = "") {
      switch (type) {
        case "state":
          this.state_text = value;
          if (value !== "") {
            this.formData.state = this.serverData2.states.filter(
              (state) => state.title == this.state_text
            )[0];
            this.onStateChange(
              this.serverData2.states.filter(
                (state) => state.title == this.state_text
              )[0].title
            );
          }

          break;
        case "city":
          this.city_text = value;
          if (value !== "") {
            this.formData.city = this.serverData2.cities.filter(
              (city) => city.title == this.city_text
            )[0];
            this.onCityChange(
              this.serverData2.cities.filter((city) => city.title == value)[0]
                .title
            );
          }

          break;
        case "area":
          this.area_text = value;

          if (value !== "") {
            try {
              this.formData.area = this.serverData2.areas.filter(
                (area) => area.title == this.area_text
              )[0];
            } catch {
              this.formData.area = null;
            }
          }

          break;
      }
    },
    createNewItem(type, id, func, value = "") {
      // console.log(type, id, func);
      this.location_type = type;
      this.locaiton_id = id;
      this.location_function = func;
      this.location_initValue = value;
      this.$nextTick(() => {
        this.$refs.location.toggleModal();
      });
    },
    async onCountryChange(val, safe = true, is_id = false) {
      if (val && val != "") {
        this.pageLoader(true);
        let data = is_id ? { country: val } : { country: val };
        await ApiService.post("/address/states/search", data)
          .then((response) => {
            this.serverData2.states = response.data.states;
            if (!safe) {
              this.formData.state = null;
              // this.address.city = null;
              // this.address.area = null;
              this.serverData2.cities = null;
              this.serverData2.areas = null;
            }
            let i = { ...this.serverData2 };
            this.serverData2 = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
            if (!safe) {
              this.formData.state = null;
              // this.address.city = null;
              // this.address.area = null;
              this.serverData2.cities = null;
              this.serverData2.areas = null;
            }

            this.dialog = false;
          });
      }
    },
    async onStateChange(val, safe = true, is_id = false) {
      if (val && val != "") {
        let item = null;
        if (is_id) {
          try {
            item = this.serverData2.states.filter(
              (state) => state.id == val
            )[0];
          } catch {
            return;
          }
        } else {
          try {
            if (
              typeof val === "object" &&
              !Array.isArray(val) &&
              val !== null
            ) {
              item = this.serverData2.states.filter(
                (state) => state.title == val.title
              )[0];
            } else {
              item = this.serverData2.states.filter(
                (state) => state.title == val
              )[0];
            }
          } catch {
            return;
          }
        }
        if (!item) {
          return;
        }

        this.formData.state = item;
        let data = { state: item.id };
        this.pageLoader(true);
        await ApiService.post("/address/cities/search", data)
          .then(async (response) => {
            this.serverData2.cities = response.data.cities;
            if (!safe) {
              this.formData.city = null;
              // this.address.area = null;
              this.serverData2.areas = null;
            }
            let i = { ...this.serverData2 };
            this.serverData2 = i;
            await this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
            if (!safe) {
              this.formData.city = null;
              // this.address.area = null;
              this.serverData2.areas = null;
            }
            this.dialog = false;
          });
      }
    },
    async onCityChange(val, safe = true, is_id = false) {
      if (val && val != "") {
        let item = null;
        if (is_id) {
          try {
            item = this.serverData2.cities.filter((city) => city.id == val)[0];
          } catch {
            return;
          }
        } else {
          try {
            if (
              typeof val === "object" &&
              !Array.isArray(val) &&
              val !== null
            ) {
              item = this.serverData2.cities.filter(
                (city) => city.title == val.title
              )[0];
            } else {
              // console.log(this.serverData2.cities)
              item = this.serverData2.cities.filter(
                (city) => city.title == val
              )[0];
            }
          } catch {
            return;
          }
        }

        if (!item) {
          return;
        }
        this.formData.city = item;
        this.pageLoader(true);
        let data = { city: item.id };
        await ApiService.post("/address/areas/search", data)
          .then(async (response) => {
            this.serverData2.areas = response.data.areas;
            if (!safe) {
              this.formData.area = null;
            }
            let i = { ...this.serverData2 };
            this.serverData2 = i;
            await this.pageLoader(false);
          })
          .catch(() => {
            if (!safe) {
              this.formData.area = null;
            }
            this.pageLoader(false);
            this.dialog = false;
          });
      }
    },
    submitData() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      if (
        this.validationValueCheck("state") ||
        this.validationValueCheck("city")
      ) {
        Swal.fire({
          title: "Warning",
          html: `<div>Invalid address!</div>`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      }
      this.pageLoader(true);
      // let data = JSON.stringify(this.entCreate);
      const data = {
        ...this.formData,
      };
      data.state = this.formData.state.id;
      data.city = this.formData.city.id;
      if (this.formData.area) {
        data.area = this.formData.area.id;
      }
      data.customer_id = this.customer_id;
      // this.setData(data, "collection_address");
      // this.resetCreateForm();
      // return true;
      ApiService.post("/shipping/pickup_orders/create-address", data)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `Record has been successfully added!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.getStep2Data().then(() => {
            this.toggleModal();
            this.pageLoader(false);
            // this.resetEditForm();
            // this.refresher();
          });
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    async resetData() {
      // console.log("reset create form2");
      this.$v.$reset();
      this.serverData2 = {
        states: null,
        cities: null,
        areas: null,
      };
      if (this.initData) {
        try {
          let copy = { ...this.serverData.pickup_order.collection_address };
          Object.entries(this.formData).forEach((localData) => {
            Object.entries(
              this.serverData.pickup_order.collection_address
            ).forEach((serverData) => {
              if (localData[0] == serverData[0]) {
                this.formData[localData[0]] = copy[serverData[0]];
              }
            });
          });
          this.old_address = {
            state: null,
            city: null,
            area: null,
          };
          if (this.serverData.pickup_order.collection_address.country) {
            await this.onCountryChange(
              this.serverData.pickup_order.collection_address.country,
              true,
              true
            );
          }
          if (this.serverData.pickup_order.collection_address.state) {
            this.old_address["state"] =
              this.serverData.pickup_order.collection_address.state;
            // await this.$nextTick(async () => {
            //   await this.onStateChange(
            //     this.serverData.pickup_order.collection_address.state,
            //     true
            //   );
            // });
            await this.onStateChange(
              this.serverData.pickup_order.collection_address.state,
              true
            );
          }
          if (this.serverData.pickup_order.collection_address.city) {
            this.old_address["city"] =
              this.serverData.pickup_order.collection_address.city;
            // await this.$nextTick(async () => {
            //   await this.onCityChange(
            //     this.serverData.pickup_order.collection_address.city,
            //     true
            //   );
            // });
            await this.onCityChange(
              this.serverData.pickup_order.collection_address.city,
              true
            );
          }
          if (this.serverData.pickup_order.collection_address.area) {
            this.old_address["area"] =
              this.serverData.pickup_order.collection_address.area;
          }
        } catch (e) {
          // console.error(e);

          this.formData = {
            title: null,
            name: null,
            surname: null,
            phone: null,
            country: null,
            state: null,
            city: null,
            area: null,
            address: null,
            address2: null,
            postal_code: null,
          };
        }
      } else {
        this.formData = {
          title: null,
          name: null,
          surname: null,
          phone: null,
          country: null,
          state: null,
          city: null,
          area: null,
          address: null,
          address2: null,
          postal_code: null,
        };
      }
    },
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
  },
  computed: {
    states: function () {
      return this.serverData2.states;
    },
    cities: function () {
      return this.serverData2.cities;
    },
    areas: function () {
      return this.serverData2.areas;
    },
    isStatesLoaded: function () {
      return !this.serverData2.states;
    },
    isCitiesLoaded: function () {
      return !this.serverData2.cities;
    },
    isAreasLoaded: function () {
      return !this.serverData2.areas;
    },
    getLocationType() {
      return this.location_type;
    },
    getLocationId() {
      return this.locaiton_id;
    },
    getLocationFunc() {
      return this.location_function;
    },
    getLocationInitValue() {
      return this.location_initValue;
    },
    titleErrors() {
      return this.handleFormValidation("title", this);
    },
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
    phoneErrors() {
      return this.handleFormValidation("phone", this);
    },
    countryErrors() {
      return this.handleFormValidation("country", this);
    },
    stateErrors: function () {
      let error = this.handleFormValidation("state", this);
      let ms = this.validationValueCheck("state");
      let old = this.oldValueText("state");
      // console.log(error, ms, old, this.old_address);
      if (ms && error[0]) {
        // error.push(ms);
        if (old) {
          error[0] = ms + " " + error[0] + ". " + old;
        } else {
          error[0] = ms + " " + error[0];
        }
      } else if (ms) {
        if (old) {
          error[0] = ms + " " + old;
        } else {
          error[0] = ms + " ";
        }
      }
      return error;
    },
    cityErrors: function () {
      let error = this.handleFormValidation("city", this);
      let ms = this.validationValueCheck("city");
      let old = this.oldValueText("city");
      // console.log(error, ms, old, this.old_address);
      if (ms && error[0]) {
        // error.push(ms);
        if (old) {
          error[0] = ms + " " + error[0] + ". " + old;
        } else {
          error[0] = ms + " " + error[0];
        }
      } else if (ms) {
        if (old) {
          error[0] = ms + " " + old;
        } else {
          error[0] = ms + " ";
        }
      }
      return error;
    },
    addressErrors() {
      return this.handleFormValidation("address", this);
    },
  },
};
</script>
